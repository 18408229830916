<template>
  <div>
    <shipblu-card :cardLoading="trackingCardLoading || ringerCardLoading" class="p-5 relative h-full">
      <div class="flex justify-between items-center mb-4">
        <p class="header text-lg">
          <span>{{ $route.params.type === 'delivery-orders' ?  $t('Delivery Attempts') + ' ' : $route.params.type === 'returns' ? $t('Return Attempts') + ' ' : $t('Cash Collection Attempts') + ' ' }}</span>
          <span class="text-red">{{ '(' + trackings.filter(item => item.status === 'delivery_attempted' || item.status === 'return_attempted').length + ')' }}</span>
        </p>
      </div>
      <div :style="`border: 1px solid ${attemptBorder(item)}; background: rgba(255, 183, 77, 0.19);`" :class="attemptBorder(item) ? (attemptBorder(item) === '#E2E2E2' ? 'bg-lightgrey' : 'bg-white') : 'border border-solid border-transparent'" class="mt-4 p-2 rounded-lg flex gap-4 justify-between items-center" v-for="(item, index) in trackings.filter(item => item.status === 'delivery_attempted' || item.status === 'return_attempted').reverse()" :key="index">
        <div class="flex gap-2 leading-tight">
          <p class="text-black font-bold text-sm">{{ index === 0 ? $t('1st') : index === 1 ? $t('2nd') : index === 2 ? $t('3rd'): '' }}</p>
          <div>
            <p class="font-medium text-black text-sm">{{ $t(item.comment) }}</p>
            <p class="text-gray text-xs mt-2">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString() }}</p>
          </div>
        </div>
        <div class="flex sm:gap-2 gap-1 items-center">
          <vx-tooltip position="top" color="#434343" :text="`${$t('This attempt is validated by the customer through myBlu app.')}`">
            <div v-if="item.customer_validation" class="flex gap-1 items-end cursor-pointer">
              <span :class="`${item.customer_validation === true ? 'text-[#28C76F]' : item.customer_validation === false ? 'text-red' : ''} material-symbols-outlined text-xl`">{{item.customer_validation === true ? 'check' : item.customer_validation === false ? 'close' : ''}}</span>
              <p :class="`${item.customer_validation === true ? 'text-[#28C76F]' : item.customer_validation === false ? 'text-red' : ''}`">{{ item.customer_validation === true ? $t('Valid') : item.customer_validation === false ? $t('Invalid') : '' }}</p>
              <img class="block ml-1" height="24" :src="`${item.customer_validation === true ? ConfirmedVerify : item.customer_validation === false ? DeniedVerify : ''}`" alt="">
            </div>
          </vx-tooltip>
          <!-- Mobile Icons -->
          <div v-if="callHistory.filter(element => element.attempt === item.id).length > 0">
            <img v-if="attemptBorder(item) === '#28C76F'" @click="openLocalRingerModal(item.id)" height="24" class="block cursor-pointer" src="@/assets/images/pages/confirmed-ringer.svg" alt="">
            <img v-if="attemptBorder(item) === '#EA5455'" @click="openLocalRingerModal(item.id)" height="24" class="block cursor-pointer" src="@/assets/images/pages/denied-ringer.svg" alt="">
            <img v-if="attemptBorder(item) === '#E2E2E2'" @click="openLocalRingerModal(item.id)" height="24" class="block cursor-pointer" src="@/assets/images/pages/ringer.svg" alt="">
          </div>
          <vx-tooltip v-if="attemptsValidation.length > 0 && attemptBorder(item) === '#28C76F'" position="top" color="#434343" :text="`${$t('ShipBlu Verify™ contacted the customer, and the delivery attempt was confirmed.')}`">
            <img class="block cursor-pointer" height="24" src="@/assets/images/pages/confirmed-verify.svg" alt="">
          </vx-tooltip>
          <vx-tooltip v-if="attemptsValidation.length > 0 && attemptBorder(item) === '#EA5455'" position="top" color="#434343" :text="`${$t('ShipBlu Verify™ contacted the customer, and the delivery attempt was not confirmed.')}`">
            <img class="block cursor-pointer" height="24" src="@/assets/images/pages/denied-verify.svg" alt="">
          </vx-tooltip>
        </div>
      </div>
    </shipblu-card>
    <local-ringer-modal :localRingerModal="localRingerModal" :attemptCalls="attemptCalls" @localRingerModal="localRingerModal = $event"/>
  </div>
</template>

<script>
import { sendRequest } from '@/http/axios/requestHelper'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import LocalRingerModal from './LocalRingerModal.vue'
import ConfirmedVerify from '@/assets/images/pages/confirmed-verify.svg'
import DeniedVerify from '@/assets/images/pages/denied-verify.svg'

export default {
  props: ['trackingCardLoading', 'trackings', 'gotVerifyAlert', 'attemptsValidation'],
  data () {
    return {
      callHistory: [],
      ConfirmedVerify,
      DeniedVerify,
      attemptCalls: [],
      ringerCardLoading: false,
      localRingerModal: false
    }
  },
  methods: {
    gotVerify () {
      this.$cookies.set('gotVerifyAlert', true, '100y')
      this.$emit('gotVerifyAlert', true)
    },
    attemptBorder (item) {
      if (item.customer_validation === true || this.attemptsValidation.filter((element) => element.attempt === item.id && element.status === 'confirmed').length > 0) {
        return '#28C76F'
      } else if (item.customer_validation === false || this.attemptsValidation.filter((element) => element.attempt === item.id && element.status === 'denied').length > 0) {
        return '#EA5455'
      } else if (item.customer_validation === null || (this.$store.state.AppActiveUser.userRole !== 'merchant' && this.attemptsValidation.filter((element) => element.attempt === item.id).length === 0)) {
        return '#E2E2E2'
      }
    },
    loadLocalRingerCall () {
      this.ringerCardLoading = true
      sendRequest(true, false, this, `api/v1/ringer/local-ringer-call/?order=${this.$route.params.orderID}`, 'get', null, true, 
        response => {
          this.callHistory = response.data.results
          this.ringerCardLoading = false
        }
      )
    },
    openLocalRingerModal (attemptID) {
      this.localRingerModal = true
      this.attemptCalls = this.callHistory.filter(element => element.attempt === attemptID)
    }
  },
  components: {
    ShipbluCard,
    LocalRingerModal
  },
  created () {
    this.loadLocalRingerCall()
  }
}
</script>

<style>
.bg-lightgrey {
  background: #F0F0F0 !important;
}
</style>